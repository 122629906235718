<template>
  <div class="center content-result judge-score">
    <div class="result-infos">
      <div class="result-work-infos">
        <div
          v-for="(work, index) in works"
          :key="index"
          class="work-info"
        >
          <span class="info-title">{{
            index + 1 + '.' + work.label + ':'
          }}</span>
          <template v-if="work.key === 'file_list'">
            <a
              v-for="(item,i) in workInfo[work.key]"
              :href="item.filePath"
              :key="i"
              title="下载附件"
              class="info-content"
            >{{ item.fileOriginName }}</a>
          </template>
          <span
            v-else
            class="info-content"
          >{{ workInfo[work.key] }}</span>
        </div>
      </div>
    </div>
    <div class="result-table">
      <el-table
        :data="ruleList"
        :span-method="objectSpanMethod"
        :summary-method="getSummaries"
        show-summary
        style="width: 100%"
      >
        <el-table-column
          v-for="schema in tableSchema"
          :key="schema.property"
          :prop="schema.property"
          :label="schema.label"
          :width="schema.width"
          align="center"
          header-align="center"
        >
          <template slot-scope="scope">
            <el-input-number
              v-if="schema.property === 'score'"
              v-model="ruleList[scope.$index].score"
              :min="0"
              :max="scope.row.maxScore"
              :precision="1"
              size="small"
            ></el-input-number>
            {{
              schema.property !== 'score' ? scope.row[schema.property] : null
            }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-form>
      <div class="center-row result-comment">
        <div style="width: 1000px;">
          <el-form
            ref="form"
            :model="judgeForm"
            :rules="rules"
            label-width="120px"
          >
            <el-form-item
              label="作品评价"
              prop="comment"
            >
              <el-input
                type="textarea"
                :rows="3"
                placeholder="请输入对该作品的评价(200字符以内)"
                v-model="judgeForm.comment"
                :maxlength="200"
              >
              </el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="center result-other">
        <el-button
          @click="onClickSubmit"
          type="primary"
        >完成评价</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import { getJudgeRule, submitJudgeScore } from '@/api/match';
import { sensitiveFilter } from '@/api/common'
const sensitiveValidator = (rule, value, callback) => {
  sensitiveFilter({ desc: value }).then(res => {
    if (!res.success) {
      callback(new Error(res.message));
    } else {
      callback();
    }
  })
}
export default {
  props: {
    workInfo: Object,
  },
  data() {
    return {
      ruleList: [],
      judgeForm: {
        comment: ''
      },
      rules: {
        comment: [
          { validator: sensitiveValidator, trigger: 'change' }
        ],
      },
      tableSchema: [
        { label: '评价指标', property: 'judgeType', width: '250' },
        { label: '指标描述', property: 'name', width: '400' },
        { label: '分值', property: 'maxScore', width: '100' },
        { label: '作品得分', property: 'score', width: '250' },
      ],
      works: [
        { label: '团队名称', key: 'team_name' },
        { label: '参赛组别', key: 'level_id' },
        { label: '作品名称', key: 'work_title' },
        { label: '作品说明', key: 'description' },
        { label: '作品附件', key: 'file_list' },
      ],
    };
  },
  computed: {
    matchDetail() {
      return this.$store.state.matchDetail;
    },
  },
  created: function () {
    // console.log(this.workInfo)
    const requestParams = {
      jwid: this.workInfo.id,
    };
    if (this.workInfo.rule_value) {
      //已经评过分
      const ruleValue = JSON.parse(this.workInfo.rule_value);
      this.judgeForm.comment = ruleValue.comment;
      this.ruleList = ruleValue.score;
      return;
    }
    //获取评分规则列表
    getJudgeRule(requestParams).then((res) => {
      if (!res.success) {
        return;
      }
      const { ruleData } = res.result;
      const dataList = JSON.parse(ruleData);
      const ruleList = [];
      dataList.map((rules) => {
        const judgeType = rules.name;
        const { metrics } = rules;
        const span = metrics.length;
        metrics.map((rule, index) => {
          const ruleItem = {
            judgeType: judgeType,
            name: rule.name,
            maxScore: parseInt(rule.score),
            score: 0,
            span: index ? 0 : span,
          };
          ruleList.push(ruleItem);
        });
      });
      this.ruleList = ruleList;
    });
  },
  methods: {
    onClickSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const { id } = this.workInfo;
          let score = 0;
          this.ruleList.map((rule) => {
            score += rule.score;
          });
          const requestParams = {
            id,
            rule_value: {
              // JSON.stringify({
              score: this.ruleList,
              comment: this.judgeForm.comment,
              // }),
            },
            score,
            comment: this.judgeForm.comment,
          };
          //检查是否符合规则
          submitJudgeScore(requestParams).then((res) => {
            const { success } = res;
            if (success) {
              this.$emit('goBackToList');
            } else {
              this.$message({
                showClose: true,
                message: '提交评分信息，遇见了错误、请联系管理员',
                type: 'error',
              });
            }
          });
        }
      })
    },

    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      //合并行
      if (columnIndex === 0) {
        return {
          rowspan: row.span,
          colspan: row.span ? 1 : 0,
        };
      }
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      // console.log('xxx', data, 'uu', columns);
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '总分';
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        } else {
          // sums[index] = '-----';
        }
      });

      return sums;
    },
    handleChange(value, scope) {
      this.ruleList[scope.$index] = value;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/views/matchDetail.scss';
</style>
