<template>
  <div class="match-detail match-judges">
    <div class="match-detail-head center">
      <div class="head-banner">
        <img class="banner-img" :src="matchDetail.bigCover">
        <div class="banner-match-info">
         <div class="item-title" style="-webkit-box-orient: vertical" title="">
            <span>{{ matchDetail.matchSeriesName }}</span
            ><br />
            <span
              >{{ matchDetail.title }}({{ matchDetail.matchProjectName }})</span
            >
          </div>
          <div class="item-info">
            <div class="flex info-unit">
              <div>主办单位：</div>
              <div>
                <span>{{
                  matchDetail.organizers && matchDetail.organizers.length
                    ? matchDetail.organizers[0]
                    : ''
                }}</span
                ><br />
                <span>{{
                  matchDetail.organizers && matchDetail.organizers.length > 1
                    ? matchDetail.organizers[1]
                    : ''
                }}</span>
              </div>
            </div>
            <div class="flex info-time">
              <div>活动时间：</div>
              <div>
                {{ $moment(matchDetail.startTime).format('YYYY-MM-DD') }}
                ---
                {{ $moment(matchDetail.endTime).format('YYYY-MM-DD') }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="head-actions">
        <div class="head-tabs">
          <el-tabs v-model="activeTab" @tab-click="changeActiveTab">
            <el-tab-pane
              v-for="(tab, index) in tabs"
              :key="index"
              v-bind:label="tab.name"
              v-bind:name="tab.key"
              v-bind:type="tab.type"
            ></el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <div class="match-detail-content center">
      <MatchJudgeContent
        :activeTab="activeTab"
        :contentType="contentType"
      ></MatchJudgeContent>
    </div>
  </div>
</template>

<script>
import MatchJudgeContent from '@/components/match/indexJudge.vue';
import {  getMatchDetailById } from '@/api/match';
import { mapState } from 'vuex';
import Vue from 'vue';
import { ACCESS_TOKEN } from '@/store/mutation-types';
export default {
  data() {
    return {
      activeTab: '1',
      contentType: 'img',
      tabs: [
        { name: '查看赛事介绍', key: '1', type: 'img' },
        { name: '查看赛事要求', key: '2', type: 'img' },
        { name: '赛事评审奖项', key: '3', type: 'img' },
        { name: '赛事附件下载', key: '4', type: 'attachment' },
        { name: '进行作品评分', key: '5', type: 'judge' },
      ],
    };
  },
  components: { MatchJudgeContent },
  created: function () {
    const id = this.$route.query.id;
    const token = Vue.ls.get(ACCESS_TOKEN);
    if ( !token) {
      this.$router.push({ path: `/login?redirect_uri=${location.href}` });
      // window.location.href = window.location.origin+"/passport/dologin?url="+encodeURI(location.href)
      return;
    }
    //1. 发送请求
    getMatchDetailById({ id: id })
      .then((res) => {
        if (!res.success) {
          //获取比赛详情失败
          that.$notify({
            title: '信息提示',
            message: '获取比赛详情失败!',
            type: 'warning',
          });
          return;
        }
        const { result } = res;
        result.organizers = result.organizers.replace(/，/gi, ',');
        result.organizers = result.organizers.split(',');
        this.$store.dispatch('SetMatchDetail', { matchDetail: result });
      })
      .catch((error) => {
        that.$notify({
          title: '登录提示',
          message: error || '登录失败：未知错误',
          type: 'warning',
        });
      });
  },
  computed: mapState({
    matchDetail: (state) => state.matchDetail,
  }),
  //  updated: function () {
   
  // },
  methods: {
    changeActiveTab(tab) {
      const index = parseInt(tab.index);
      this.activeTab = tab.name;
      this.contentType = this.tabs[index].type;
    },
  },
};
</script>
<style scoped>
.el-card /deep/ .el-card__body {
  display: flex;
  width: 100%;
  padding: 0;
}
.el-tabs /deep/ .el-tabs__nav-scroll {
  display: flex;
  justify-content: center;
  align-items: center;
}
.el-tabs /deep/ .el-tabs__item {
  font-weight: bold;
  color: #fff;
  padding: 0 38px !important;
}
.el-tabs /deep/ .is-active {
  color: #ffff00;
}
.el-tabs /deep/ .el-tabs__active-bar {
  height: 3px;
  background-color: #ffff00;
}
.el-tabs /deep/.el-tabs__nav-wrap::after {
  position: static !important;
}
</style>
<style lang="scss" scoped>
@import '@/assets/css/views/match.scss';
.banner-img{
  position: absolute;
  width: 100%;
  height: 330px;
  z-index: 1;
}
</style>
