<template>
  <div class="judge-content">
    <!-- 图片组件 -->
    <div v-if="contentType == 'img'" class="center content-img">
      <img v-if="activeTab === '1'" :src="matchDetail.imgDesc" />
      <img v-else-if="activeTab === '2'" :src="matchDetail.imgRequired" />
      <img v-else-if="activeTab === '3'" :src="matchDetail.imgPrize" />
    </div>
    <!-- 附件组件 -->
    <div
      v-else-if="contentType == 'attachment'"
      class="center content-attachment"
    >
      <el-tooltip
        v-if="matchStatus === 'end'"
        class="item"
        effect="dark"
        content="比赛已经结束，不支持下载附件"
        placement="top"
      >
        <a href="#">{{ matchDetail.attachName }}</a>
      </el-tooltip>
      <a v-else :href="matchDetail.attachSrc">{{ matchDetail.attachName }}</a>
    </div>
    <!-- 评价组件 -->
    <JudgeContent
      v-else-if="contentType == 'judge'"
      :activeMenu="activeTab"
      v-bind="$attrs"
    />
    <div class="fix-opts judge-entery">
      <ul>
        <li @click="GoTo('/match')" title="返回赛事">
          <el-tooltip class="item" effect="dark" content="返回赛事" placement="left">
            <img src="@/assets/image/match/to-return.svg" alt="返回赛事">
          </el-tooltip>
          <!-- <span slot="title">返回赛事</span> -->
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import MatchTable from '@/components/match/MatchTable.vue';
import MyResult from '@/components/match/MyResult.vue';
import JudgeContent from '@/components/match/JudgeContent.vue';

export default {
  name: 'MatchJudgeContent',
  props: {
    activeTab: String,
    contentType: String,
  },
  data() {
    return {
      matchStatus: 'start',
    };
  },
  computed: mapState({
    matchDetail: (state) => state.matchDetail,
  }),
  updated: function () {
    const { startTime, endTime } = this.matchDetail;
    const nowTime = this.$moment(new Date()).valueOf();
    const start_time = this.$moment(startTime).valueOf();
    const end_time = this.$moment(endTime).valueOf();
    if (nowTime < start_time) {
      //比赛未开始
      this.matchStatus = 'notStart';
    } else if (start_time <= nowTime && nowTime <= end_time) {
      // &&nowTime <= end_time
      //比赛进行中
      this.matchStatus = 'start';
    } else {
      //比赛已结束
      this.matchStatus = 'end';
    }
  },
  components: { MatchTable, MyResult, JudgeContent },
  methods:{
    GoTo(path){
      if(path == "/judge"){
        return this.gotoJudgePage()
      }
      this.$router.push("/match")
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/views/matchDetail.scss';
</style>
