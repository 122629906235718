<template>
  <div class="content-table">
    <div
      v-if="isJudge"
      class="center content-status"
    >
      <div
        style="float:left;width:100%"
        v-if="tableType != 'score'"
      >
        <el-form
          :inline="true"
          :model="queryParam"
        >
          <el-form-item>
            <el-input
              @clear="getJudgeList()"
              clearable
              style="width:350px"
              v-model="queryParam.name"
              placeholder="团队/作品名称搜索"
              @change="getJudgeList()"
            >
              <el-button
                @click="getJudgeList()"
                slot="append"
                icon="el-icon-search"
              ></el-button>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-select
              style="width:200px"
              clearable
              @change="getJudgeList()"
              v-model="queryParam.level"
              placeholder="参赛组别筛选"
            >
              <el-option
                v-for="(level,i) in matchDetail.levelSetting"
                :label="level"
                :key="i"
                :value="level"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              style="width:200px"
              clearable
              @change="getJudgeList()"
              v-model="queryParam.status"
              placeholder="评价状态筛选"
            >
              <el-option
                label="未评价"
                value="1"
              ></el-option>
              <el-option
                label="已评价"
                value="2"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <el-table
        v-if="tableType === 'list'"
        :data="evaluateData"
        style="width: 100%"
      >
        <el-table-column
          v-for="schema in evaluateSchema.default"
          :key="schema.property"
          :prop="schema.property"
          :type="schema.property"
          :label="schema.label"
          :width="schema.width"
          align="center"
          header-align="center"
        >
        </el-table-column>
        <el-table-column
          v-for="schema in evaluateSchema.status"
          :key="schema.property"
          :prop="schema.property"
          :type="schema.property"
          :label="schema.label"
          :width="schema.width"
          align="center"
          header-align="center"
        >
          <template slot-scope="scope">
            <div
              v-if="schema.property === 'work_status'"
              class="center row-status"
            >
              <span>{{
                scope.row.rule_value ? '已评价' : '未评价'
              }}</span>
              <span @click="gotoScoreTable(scope.row)">进入评价</span>
            </div>
            <!-- <div v-else>{{ scope.row[schema.property] }}</div> -->
          </template>
        </el-table-column>
      </el-table>
      <JudgeScore
        v-else
        :workInfo="workInfo"
        @goBackToList="goBackToList"
      />
    </div>
    <span
      v-else
      class="tip-text"
    >
      {{ message }}
    </span>
    <div
      v-if="tableType === 'list' && isJudge"
      class="table-pagination"
    >
      <el-pagination
        background
        layout="prev, pager, next, jumper"
        :total="total"
        :current-page="pageNo"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import JudgeScore from '@/components/match/JudgeScore.vue';
import { getJudgeList } from '@/api/match';
export default {
  data() {
    return {
      queryParam: {
        name: null,
        level: null,
        status: null
      },
      tableType: 'list',
      isJudge: false,
      message: '',
      workInfo: {},
      pageNo: 1,
      pageSize: 10,
      total: 0,
      evaluateSchema: {
        default: [
          { label: '序号', property: 'index', width: '50' },
          { label: '团队名称', property: 'team_name', width: '141' },
          { label: '参赛组别', property: 'level_id', width: '326' },
          { label: '作品名称', property: 'work_title', width: '93' },
          { label: '得分', property: 'score', width: '193' },
        ],
        status: [{ label: '状态', property: 'work_status', width: '297' }],
      },
      evaluateData: [],
    };
  },
  computed: {
    matchDetail() {
      if (this.$store.state.matchDetail.levelSetting && typeof this.$store.state.matchDetail.levelSetting == 'string') {
        let levelSettings = [];
        levelSettings = JSON.parse(this.$store.state.matchDetail.levelSetting)
        this.$store.state.matchDetail.levelSetting = levelSettings;
      }
      return this.$store.state.matchDetail;
    },
  },
  created() {
    this.getJudgeList();
  },
  methods: {
    gotoScoreTable(scope) {
      //1、跳转评分表格
      this.tableType = 'score';
      this.workInfo = { ...scope };
    },
    goBackToList() {
      this.tableType = 'list';
      //刷新数据
      this.getJudgeList();
    },
    handleCurrentChange(val) {
      this.getJudgeList({ pageNo: val });
      this.pageNo = val;
    },
    getJudgeList(params) {
      const requestParams = {
        matchId: this.matchDetail.id,
        stageId: this.matchDetail.currentStage.id,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        ...params,
        ...this.queryParam
      };
      // console.log(requestParams)
      getJudgeList(requestParams).then((res) => {
        if (!res.success) {
          this.message = '您不是该赛事的评委，无法查看';
          return;
        }
        const { result } = res;
        this.isJudge = true;
        this.evaluateData = result.records;
        this.total = result.total;
      });
    },
  },
  components: { JudgeScore },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/views/matchDetail.scss';
</style>
